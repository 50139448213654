import { types } from './type';
import API from '../api';
const { assetList, InYardList, closedOut,ticketDetails,yardTicketPerforma } = API;
let count1=null;
let count2=null;
let count3=null;
export const getAssetList = (page,searchText) => (dispatch, getstate) => {
  const prevState = getstate().myYard.assetList;
  // if (prevState.length === 0) {
    dispatch({ type: types.SHOW_LOADING,loadingName:'loadingAsset', loading: true });
  // }
  let data = JSON.parse(localStorage.getItem('data'));
  let arr = data?.TabParams&&data.TabParams.filter((item) =>
    ['Rejected', 'Check-In'].includes(item.param_name)
  );
  let check_in = arr&&arr[0]?.param_value;
  let status = arr&&arr[1]?.param_value;
  assetList(check_in, status,page,searchText)
    .then((resp) => {
      if(count1===null){
        count1=resp.data.count
      }
      const assetList=getstate().myYard.assetList
      const mergeResult = [...assetList];
      resp.data.data.forEach((item)=>{
        let filter = mergeResult.filter(x => x.asset_txns_id == item.asset_txns_id);
        if(filter.length <= 0){
          if(count1!==null&&(count1<resp.data.count)){
            mergeResult.unshift(item);
          }
          else{
            mergeResult.push(item);
          }
        } else {
          mergeResult.map((_data,i)=>{
            if(_data.asset_txns_id===item.asset_txns_id){
              mergeResult[i]=item
            }
          })
        }
      })
      count1=resp.data.count

      dispatch({
        type: types.ASSET_LIST,
        count:resp.data.count,
        page:page!==0?page:getstate().myYard.assetPage,
        data: mergeResult,
      });
    })
    .catch((er) => {
      dispatch({ type: types.SHOW_LOADING,loadingName:'loadingAsset', loading: false });
    });
};

export const getInYardList = (page,searchText) => (dispatch, getstate) => {
  const prevState = getstate().myYard.inYardList;
  // if (prevState.length === 0) {
    dispatch({ type: types.SHOW_LOADING,loadingName:'loadingInYard', loading: true });
  // }
  InYardList(page,searchText)
    .then((resp) => {
      if(count2===null){
        count2=resp.data.data.count
      }
      const inYardList=getstate().myYard.inYardList
      const mergeResult = [...inYardList];
      resp.data.data.rows.forEach((item)=>{
        let filter = mergeResult.filter(x => x.asset_txns_details.asset_txns_id == item.asset_txns_details.asset_txns_id);
        if(filter.length <= 0){
          if(count2!==null&&(count2<resp.data.data.count)){
            mergeResult.unshift(item);
          }
          else{
            mergeResult.push(item);
          }
        } else {
          mergeResult.map((_data,i)=>{
            if(_data.asset_txns_details.asset_txns_id===item.asset_txns_details.asset_txns_id){
              mergeResult[i]=item
            }
          })
        }
      })
      count2=resp.data.data.count

      dispatch({
        type: types.INYARD_LIST,
        count:resp.data.data.count,
        page:page!==0?page:getstate().myYard.inYardPage,
        data: mergeResult,
      });
    })
    .catch((er) => {
      dispatch({ type: types.SHOW_LOADING,loadingName:'loadingInYard', loading: false });
    });
};
export const getClosedOutList = (page,searchText) => (dispatch,getstate) => {
  const prevState = getstate().myYard.closedOutList;
  // if (prevState.length === 0) {
    dispatch({ type: types.SHOW_LOADING,loadingName:'loadingClosedOut', loading: true });
  // }
  let data = JSON.parse(localStorage.getItem('data'));
  let pikdup = data.TabParams.filter(
    (item) => item.param_name === 'Checkout'
  );

  let pikdupValue = pikdup[0].param_value;
  closedOut(pikdupValue,page,searchText)
    .then((resp) => {
      if(count3===null){
        count3=resp.data.count
      }
      const closedOutList=getstate().myYard.closedOutList
      const mergeResult = [...closedOutList];
      resp.data.data.forEach((item)=>{
        let filter = mergeResult.filter(x => x.asset_txns_id == item.asset_txns_id);
        if(filter.length <= 0){
          if(count3!==null&&(count3<resp.data.count)){
            mergeResult.unshift(item);
          }
          else{
            mergeResult.push(item);
          }
        } else {
          mergeResult.map((_data,i)=>{
            if(_data.asset_txns_id===item.asset_txns_id){
              mergeResult[i]=item
            }
          })
        }
      })
      count3=resp.data.count

      dispatch({
        type: types.CLOSEDOUT_LIST,
        count:resp.data.count,
        page:page!==0?page:getstate().myYard.closedOutPage,
        data: mergeResult,
      });
    })
    .catch((er) => {
      dispatch({ type: types.SHOW_LOADING,loadingName:'loadingClosedOut', loading: false });
    });
};
let ticketDetailsId=""
export const getTicketDetails = (ticket_id) => (dispatch,getstate) => {
  const prevState = getstate().myYard.ticketDetails;
  if (prevState.length === 0) {
    dispatch({ type: types.SHOW_LOADING, loading: true });
  }else if(ticketDetailsId!==ticket_id) {
    dispatch({ type: types.SHOW_LOADING, loading: true });
  }
  ticketDetails(ticket_id)
    .then((resp) => {
      ticketDetailsId=ticket_id
      dispatch({
        type: types.TICKET_DETAILS,
        data: resp.data.data,
      });
    })
    .catch((er) => {
      dispatch({ type: types.SHOW_LOADING, loading: false });
    });
};


let proformaID=""
export const getProforma = (ticket_id) => (dispatch,getstate) => {
  const prevState = getstate().myYard.proformaData;
  if (prevState===null) {
    dispatch({ type: types.PROFORMA_LOAD, loading: true });
  }else if(proformaID!==ticket_id) {
    dispatch({ type: types.PROFORMA_LOAD, loading: true });
  }
  yardTicketPerforma(ticket_id)
    .then((resp) => {
      proformaID=ticket_id
      dispatch({
        type: types.PROFORMA_DATA,
        data: resp.data.data,
      });
    })
    .catch((er) => {
      dispatch({ type: types.PROFORMA_LOAD, loading: false });
    });
};

export const deleteByAssetTxnsId=(listName,id)=> (dispatch,getstate) =>{
  if(listName==="assetList"){
    const prevState = getstate().myYard.assetList;
    const assetCount = getstate().myYard.assetCount;
    const filtered = prevState.filter((item) => item.asset_txns_id !== id);
      count1=count1-1
      dispatch({
        type: types.ASSET_LIST,
        count:assetCount-1,
        page:getstate().myYard.assetPage,
        data: filtered,
      });
  }else if(listName==="inYardList"){
    const prevState = getstate().myYard.inYardList;
    const assetCount = getstate().myYard.inYardCount;
    const filtered = prevState.filter((item) => item.asset_txns_details.asset_txns_id !== id);
      dispatch({
        type: types.INYARD_LIST,
        count:assetCount-1,
        page:getstate().myYard.inYardPage,
        data: filtered,
      });
    }
}